import React from 'react'
import NextProject from '../components/NextProject'

const Upcoming = () => {
  return (
    <>
      <NextProject />
    </>
  )
}

export default Upcoming